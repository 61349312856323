export const fields = [
    { key: 'code', label: "No. Transaksi", _style:'min-width:100px' },
    { key: 'assignment', label: "No. Penugasan", _style:'min-width:100px' },
    { key: 'complaint', label: "No. Pengaduan", _style:'min-width:100px' },
    { key: 'date', label: "Tanggal Transaksi", _style:'min-width:100px' },
    { key: 'user', label: "Dibuat Oleh", _style:'min-width:100px' },
    { key: 'remark', label: "Catatan", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
