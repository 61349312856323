<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <!-- <div class="col">
                <button class="btn btn-sm btn-primary" @click="addUsage()">
                  Tambah Usage
                </button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            style="max-height: 300px; overflow: auto;"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                
                <CButton
                  v-if="item.id != usage.id"
                  @click="hapus(item)"
                  color="danger"
                  square
                  size="sm"
                >
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"
            @paginate="pagination"
          />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit Usage' : 'Tambah Usage'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
  
    <div class="row">
        <div class="col">
          <CInput
            v-model="form.code"
            label="Code"
            placeholder="Autonumber"
            disabled
          />
          <CInput
            v-model="form.remark"
            label="Catatan"
            placeholder="ketik disini"
          />
        </div>  
        <div class="col">
          <CInput
            v-model="form.date"
            label="Date"
            type="date"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.assignment"
            label="No. Penugasan"
            placeholder="ketik disini"
            type="text"
            disabled
          />
          <CInput
            v-model="form.complaint"
            label="No. Pengaduan"
            placeholder="ketik disini"
            type="text"
            disabled
          />
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Nama Barang</th>
            <th>Qty</th>
            <th> 
             
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.item_usage_details" :key="index">
            <td>
              <v-select
                v-model="item.item_id"
                placeholder="Pilih Barang"
                :options="computedBarang.filter((n) => n)"
              ></v-select>
            </td>
            <td>
              <CInput
                v-model="item.qty"
                placeholder="Qty"
                type="number"
              />
            </td>
            <td>
              <button @click="deleteBarang(index)" class="btn btn-sm btn-danger">
                Hapus
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="add" class="btn btn-sm btn-primary">
          + Tambah Barang
      </button>


      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/usage"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      roles: [],
      regional_apparatuses: [],
      usage: [],
      page: 1,
      total: 0,
      form: {
        item_usage_details: [],
      },
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
      barangs: [],
    }
  },
  methods: {
    deleteBarang(index) {
      (this.form.item_usage_details.splice(index, 1))
    },
    onChange(event) {
      console.log(event, "event")
    },
    add() {
      this.form.item_usage_details.push({
        item_id: null,
        qty: 0,
      })
    },
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()

      this.form.item_usage_details.forEach((element) => {
        element.item_id = element.item_id.value
      });

      this.$store
        .dispatch("usage/addUsage", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModal = false
          this.form = {
            item_usage_details: []
          }
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      item.date = this.$moment(item.date, 'Do MMMM YYYY').format('YYYY-MM-DD');
      this.form = item
      console.log(this.form)
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()

      this.form.item_usage_details.forEach((element) => {
        element.item_id = element.item_id.value
      });
      
      this.$store
        .dispatch("usage/updateUsage", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data usage")
          loading.hide()
          this.createModal = false
          this.form = {
            item_usage_details: []
          }
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("usage/deleteUsage", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data usage")
            this.form = {
              item_usage_details: []
            }
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("usage/getUsage", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.item)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()

          this.getDataRole()
          this.getDataPD()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataRole() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("role/get", params)
        .then((resp) => {
          this.roles = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataPD() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("perangkat_daerah/get", params)
        .then((resp) => {
          this.regional_apparatuses = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addUsage() {
      this.isUpdate = false
      this.form = {
        item_usage_details: []
      }
      this.createModal = true
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    getDataBarang() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("item/getItem", params)
        .then((resp) => {
          this.barangs = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,
          user: item.user ? item.user.full_name : '-',
          assignment: item.assignment ? item.assignment.no_assignment : '-',
          complaint: item.complaint ? item.complaint.no_complaint : '-',
          date: this.$moment(item.date).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedBarang() {
      if (this.form.item_usage_details.length > 0) {
        this.form.item_usage_details.forEach(element => {
          if (element.item_id && !element.item_id.value) {
            let label = this.barangs.find((element_barang) => {
              return element_barang.id == element.item_id
            });
            let barang = {
              value: element.item_id,
              label: label.name,
            }
            element.item_id = barang;
          }
        });
      }
      return this.barangs.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
      });
    },
  },
  mounted() {
    this.getData();
    this.getDataBarang();

    let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    let wrapper_2 = document.querySelector('#wrapper2');
    wrapper_1.onscroll = function() {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
      
    wrapper_2.onscroll = function() {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>